import useSWR from 'swr'
import { getMaps } from "../data/maps";
import { Entity, getEntities } from "../model/entities";
import MapImage from "./MapImage";

type kadaverTypes = "door" | "mob" | "hammer" | "elite" | "stone" | "haka" | "ball" | "start" | "stylus" | "book" | "map" | "null" | 0 | null;

async function fetcher(url: string): Promise<kadaverTypes[]> {
   console.log(`Fetching ${url}`);
   const map = await fetch(url);
   const json = await map.json();
   return json.content;
}

const KadaverCheck = () => {

   return <table><tbody>
      { Array(50).fill(0).map((_, zStage) => {
         const stage = zStage + 1;
         return Array(5).fill(0).map((_, n) => {
            const { data, error } = useSWR(`https://kadaver.site/api/data?floor=${stage}&version=${n+1}`, fetcher);
            if (data) {
               var map = Array(8).fill(0).map(_ => Array(5).fill(0).map(_ => undefined) as (Entity|undefined)[]);
               if (data.length !== 40) {
                  console.log(`Invalid data length ${data.length} for stage ${stage} map ${n}: ${data}`);
               }
               data.forEach((entity, i) => {
                  if (i < 40) {
                     var e : Entity|undefined;
                     switch (entity) {
                        case "map": e = "map"; break;
                        case "ball": e = "globe"; break;
                        case "stylus": e = "feather"; break;
                        case "hammer": e = "hammer"; break;
                        case "book": e = "book"; break;
                        case "start": e = "entrance"; break;
                        case "door": e = "exit"; break;
                        case "mob": e = "monster"; break;
                        case "elite": e = "elite"; break;
                        case "haka": e = "boss"; break;
                        case "stone": e = "boulder"; break;
                        case 0: e = undefined; break;
                        case null: e = "empty"; break;
                        case "null": e = "empty"; break;
                        default: console.log(entity);
                     }
                     map[Math.floor(i / 5)][i % 5] = e;
                  }
               });

               var realN = n;
               if (stage == 42 && n >= 3) {
                  realN = 7 - n;
               }
               var realMap = getMaps(stage)[realN];


               var counts = map.reduce((acc, row) => row.reduce((acc, cell) => { if (cell) { acc[cell] = (acc[cell]||0) + 1; } return acc; }, acc), {} as Partial<Record<Entity, number>>);
               var missing = getEntities().map(([name, entity]) => {
                  if (entity.count && entity.count(stage) !== (counts[name]||0)) {
                     const delta = entity.count(stage) - (counts[name]||0);
                     return <div key={name}>{delta > 0 ? `${delta} missing ${name}` : `${-delta} extra ${name}`}</div>;
                  }
               }).filter(_ => _);

               var realCounts = realMap.reduce((acc, row) => row.reduce((acc, cell) => { if (cell) { acc[cell] = (acc[cell]||0) + 1; } return acc; }, acc), {} as Partial<Record<Entity, number>>);
               var realMissing = getEntities().map(([name, entity]) => {
                  if (entity.count && entity.count(stage) !== (realCounts[name]||0)) {
                     const delta = entity.count(stage) - (realCounts[name]||0);
                     return <div key={name}>{delta > 0 ? `${delta} missing ${name}` : `${-delta} extra ${name}`}</div>;
                  }
               }).filter(_ => _);


               var match = map.every((row, i) => row.every((cell, j) => cell === realMap[i][j]));
               if (!match) {
                  return <tr key={`${stage}-${n}`} style={{verticalAlign: "top"}}>
                     <td style={{paddingBottom: "20px"}}>
                     <div>Kadaver</div>
                     <MapImage data={map} stage={stage} map={n} source="kadaver.site" />
                     {missing}
                     </td><td  style={{paddingBottom: "20px"}}>
                     <div>Whack</div>
                     <MapImage stage={stage} map={realN} />
                     {realMissing}
                     </td>
                  </tr>;
               }
            }
         })
      })
   }</tbody></table>;

};
 
export default KadaverCheck;
