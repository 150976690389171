import { Entity } from "../model/entities";

const maps: Partial<Record<number, string[][]>> = {
    1: [
        ["XNNHN", "   NE", "BNNN ", " NNN ", "E EBB", "B  SN", "  B  ", "_   N"],
        ["XN  N", " B   ", "HN NB", "N NNE", "ENE B", "N  NS", "   BN", "_ BN "],
        [" XN N", " SNNN", " HEN ", " N  N", " EE  ", " BNNB", " NB  ", "_ NBB"],
        ["  BNX", "NHEN ", "E  NN", "B E N", "  BN ", "BNNNB", "  SN ", "_  N "],
        ["NXNEN", "S N B", "N NNB", "N B N", "BN BN", "EHE  ", "  N  ", "_    "]
    ],
    2: [
        ["N   X", "EB  N", "   NB", "FN NE", " N   ", "NENNN", " B NB", "_ NSB"],
        ["X N N", " N EN", "N NNE", "BNSN ", "E  BB", "  NNF", "  N  ", "_ B B"],
        ["NNXNE", " N NN", "NN BB", "B BNE", "    N", " FBNN", "    S", "_   E"],
        ["N ENX", " B  N", "B NN ", "SNEB ", " BNNN", " N BN", " FN  ", "_   E"],
        ["NXNN ", " BE N", "EBN  ", " N  B", "NN  S", "  FNN", " N NB", "_ E B"]
    ],
    3: [
        ["N XMN", "BB   ", "BE  N", "N  NN", "NB NN", "NSE  ", " NN  ", "_  BE"],
        ["BNXBE", "    N", "BN N ", "N   N", "SNNN ", "E  NM", "  BNN", "_  EB"],
        ["XB NN", "NB  N", "   M ", "E NNN", " BNNN", "NBBN ", "  E S", "_ E  "],
        [" XM B", "BN  E", "NNNNN", "NNBN ", "     ", "  NE ", " BSE ", "_ BNN"],
        [" BNXN", "E  NE", "NS N ", " N N ", " BN N", "N B B", "  M N", "_ BNE"]
    ],
    4: [
        ["NBXNN", "G  NB", "E S  ", " NN N", "N  B ", "N EBN", " E   ", "_ NBN"],
        ["NNENX", "  NGB", "N BNE", "BB   ", " E N ", "NNN N", "   BN", "_   S"],
        ["XBE E", "NBGNN", "N BNN", "NB E ", "N   N", "NN   ", "  NS ", "_  B "],
        ["  X B", "NE   ", "E  NN", "BGNNN", "NBNBN", "  E N", "   BN", "_ N S"],
        ["XEN  ", "NE   ", " N   ", "ENBNN", " BNBN", " GSNN", " B N ", "_  BN"]
    ],
    5: [
        [" EXNN", "NNN  ", "E  NB", " B N ", "N  BN", "NBS N", " NBE ", "_   K"],
        [" NXNE", " BBK ", " N NN", "NN SN", " BN N", "EBN N", "  EB ", "_    "],
        ["NX BE", "NN  N", "E  NN", "BB NB", " ESN ", "KN N ", "   N ", "_ B N"],
        ["EXNN ", "   SE", " BNK ", "NB  B", " N  N", "NN  E", " BNBN", "_ NN "],
        ["  XNN", "K BN ", "NENNB", "E SN ", "E  N ", "BN  N", " BN  ", "_  NB"]
    ],
    6: [
        ["NXN B", "BHB N", "SNENF", "E  NN", "  BN ", "N  N ", " N N ", "_ E B"],
        ["  ENX", " HNEN", " B BS", "NN  F", " NNB ", "N  NB", " NN E", "_ NB "],
        ["NNX  ", "B BSB", " BNN ", "N E N", "NN EF", "NN  N", " B  H", "_ E N"],
        ["EXBN ", "FHNNB", "N  N ", "  NES", "ENBNN", "N   B", " NB  ", "_  N "],
        ["XNNNB", "NNN H", "N S B", "  N E", "E BBB", "NN NF", "   NE", "_    "]
    ],
    7: [
        [" MNX ", "NBNB ", " E B ", "  S N", "ENEB ", "NNNNB", "  HN ", "_ NN "],
        ["E NX ", "NBNBN", " BBN ", " SNN ", "MHE  ", " BENN", " N NN", "_    "],
        ["H X  ", "EE NN", " NB  ", " N EN", "N  NM", "NBB B", " NBN ", "_ SNN"],
        ["EXBNN", "N NNN", "  B N", "  N B", "M B S", " ENNH", "   N ", "_ NBE"],
        ["BX  N", "E  NN", " NHEB", "NBNNB", "NNB S", " MN N", " N   ", "_ E  "]
    ],
    8: [
        ["NNNNX", " NS N", " ENB ", " G  N", " HEN ", "  EBN", " BB  ", "_ NBN"],
        ["  NX ", "NNN B", "GNHNN", "NNEBS", "N BE ", " BNN ", " B   ", "_  E "],
        ["NNEXN", " BESN", "   E ", "N  BB", "BGN N", "   NN", " BN  ", "_ NNH"],
        ["N NXE", "  NB ", "NNE  ", "NBB  ", "NNGN ", "SHNEB", "  B  ", "_ N N"],
        ["N  BX", " S BB", "BEN E", "NNNN ", " EN N", "  GNN", "   HN", "_ BN "]
    ],
    9: [
        ["B EXN", " N EN", "H  N ", "  BEN", "BK EB", " BN N", "  NS ", "_ NNN"],
        ["EHNXN", "BE NB", " NB B", "SENEN", "N  N ", "N  N ", "   BN", "_   K"],
        ["HB BX", "NNN E", "NENEN", " ENSN", " N  N", " KB N", "  B  ", "_   B"],
        ["  NNX", "B NNN", "EKEBE", "NBNNH", " SNBN", "     ", "  N  ", "_ EB "],
        [" B BX", "NBK B", "N  BS", "H NNE", "EN  N", "NENN ", "  N E", "_ N  "]
    ],
    10: [
        ["NBEXB", "N E  ", " NNBN", "   SN", " N  N", "NFEE ", " NN B", "_  MB"],
        ["ENXNN", "B  NB", "N   M", "NEE N", "B N  ", "NNSFN", "    E", "_  BB"],
        [" XNE ", "E BNM", " NE S", "N  BE", "NNNN ", "NF  N", "  B N", "_ BB "],
        ["NNEEX", "NB   ", "F E N", "N N N", "B NSN", "   B ", " MNBB", "_ EN "],
        ["XEBN ", "EMNBN", " N NE", "NB   ", "N FN ", "NN EB", "    N", "_ SB "]
    ],
    11: [
        ["NN XB", "FE N ", "N NBB", " BBNG", "N   E", "N ESE", " NNN ", "_ N  "],
        ["NNXNF", "N BBN", "N  BS", "  N  ", "EBN G", "  BEN", "   EN", "_ ENN"],
        ["B XEN", "BNS  ", " NNE ", "E G B", "NBNNN", "NFNNB", "    N", "_   E"],
        ["NXN E", " NB N", " EENN", "  B B", "BE GN", "  N S", "  FNN", "_ NNB"],
        ["BNEX ", "E ESF", "B N  ", "N NNN", " B  E", "BGNNN", " N NN", "_   B"]
    ],
    12: [
        ["N NNX", "   EN", "SE B ", "E NEN", " FNBB", "N N K", " BNN ", "_ N B"],
        ["X N  ", "BBN E", "E  SN", " BNNF", "NB NN", "BN  E", " NNEN", "_   K"],
        ["   BX", " FNN ", " NEE ", "NSBNN", " NBBN", "BE  N", " NEKN", "_ N  "],
        ["N BXN", "BNEN ", "N E E", "NNBN ", " F KE", "N  BN", "  NBS", "_  N "],
        ["NN XN", "ENNN ", "   NF", "BEB E", "BB  N", "NKENB", "   SN", "_ N  "]
    ],
    13: [
        ["  XBE", "BN NN", "E N E", "  MEN", "BNBNG", "SNN  ", "  BN ", "_ N N"],
        ["EXNBE", "    N", "B EBN", "NM GE", "NN   ", "BBNN ", "   SN", "_ NNN"],
        ["NE  X", "ENNEN", "SB GN", "BMBNN", " EBNB", "  NNN", "   N ", "_    "],
        ["NXBEN", "SENBN", "G N N", "B  B ", "  NEN", "M   N", " B N ", "_ NNE"],
        ["EXNEB", " N BS", " NNMG", "NNBN ", "B  E ", "B NNN", "  EN ", "_   N"]
    ],
    14: [
        ["N ENX", "NNNBN", " B  E", "S BEM", "KN   ", "BB   ", " NN N", "_ NEN"],
        ["N  XE", "NKN N", "  BN ", "BSN N", "BNEB ", "  NNN", "   BE", "_ MEN"],
        [" X NS", "N   N", "E E  ", "BN NN", "NN NE", "E KMN", " NB N", "_ BBB"],
        ["NXEBN", "NNNBN", "  E N", "   NB", "B  N ", " NM N", " EEKB", "_ S N"],
        ["KN X ", "NBSME", "NN   ", " N BB", "BN N ", "N NEN", "  NE ", "_ BEN"]
    ],
    15: [
        [" X BN", "NN NN", "BNGEB", "K N  ", "N  E ", "N EEB", " SNBN", "_   N"],
        [" GX N", "E  NB", " E NN", " NN S", "N E E", "NB NK", "  BNN", "_ NBB"],
        ["S NXG", "B NNE", "BN N ", " EENN", "    N", "  KE ", " NNNB", "_ NBB"],
        ["N XNB", "NNN  ", "BNES ", "KN   ", "BEBG ", "  NNN", " BEE ", "_ NN "],
        [" XKNE", " NBN ", "N SNB", "EN  N", " NBBN", "  N E", " NNBG", "_  E "]
    ],
    16: [
        ["   BX", "MGBN ", "  NNN", "SEEBN", "NEBEN", "N   N", " K N ", "_ BNN"],
        ["XBBSN", "EEN  ", "N NNB", "NNGMN", "  NE ", "NN BE", " B KN", "_    "],
        ["N XNE", "N    ", "NSE N", "BKNEN", "NMN N", " BBB ", " B  N", "_ GNE"],
        [" XE M", " NBN ", "NB N ", "BN  N", "B B E", "EEN S", " KNNN", "_ NGN"],
        ["ES XN", "ENN K", "N N  ", "NMBNB", "   NE", "NBN B", " GNN ", "_  EB"]
    ],
    17: [
        ["S NXN", "EBEB ", "K  NE", " BFN ", "NNGBN", " N  N", "  NEN", "_  EB"],
        [" N XN", "GBNEN", "  BBN", "  BEN", "EN  K", "N   N", " NNEB", "_ SEF"],
        ["N  X ", "NNNSN", "  K E", "BEBE ", "N EFE", "BGNNB", " BN  ", "_ NN "],
        ["XN  N", "B NB ", "N BBN", "G EE ", "N B F", "N NNK", " E SN", "_ NEE"],
        ["N NXE", "NNNNE", "BGK N", "E   B", "EEN F", "  S  ", "  NNB", "_ BNB"]
    ],
    18: [
        ["MNNNX", "SBB  ", "E  E ", " K BB", "NNNEN", "FEBN ", "  N  ", "_ NEN"],
        ["  NNX", "EN N ", "  KN ", "BFMES", "  ENB", "BNBN ", " ENBE", "_ NN "],
        ["  MBX", "NEE N", "N BNN", "   NE", "N EKN", "NS FB", " E BN", "_ N B"],
        ["B X F", "ESNBN", "  EEN", "N NKM", " NB B", "NBE E", "  N N", "_  NN"],
        ["  ENX", " NKNS", " BB N", " N E ", "EBN M", "FBEN ", " ENNN", "_ NB "]
    ],
    19: [
        ["FXN B", "G NEN", " NENB", "MNN B", "    N", " EB E", " N BN", "_ SNE"],
        ["NB SX", "  N B", "EE NB", " ENM ", "NE NG", "BNNBN", "  E N", "_ F N"],
        ["B  BX", " FNNS", "  NBN", "BEM E", " E NB", "N NNN", " ENGE", "_  N "],
        ["XSB E", "N B N", "  MN ", "  NEN", "NN BF", "EBNBE", " EGN ", "_  NN"],
        [" XE  ", "NNN S", "EB   ", " BNNE", "NNBNM", " N NF", " EBN ", "_ GBE"]
    ],
    20: [
        [" NX E", "N N B", "NEENG", "  NE ", "HNNSB", " K BB", " N NN", "_ EB "],
        [" XNNN", " K EE", "ENB N", "  BB ", " HNNS", "  BNB", " EN N", "_ GNE"],
        [" XENN", "G EB ", " EHSK", "E NNN", " NBN ", "  NEN", "  BBB", "_ N N"],
        ["  XHG", "N  NE", "BK NE", "SNBNB", "ENEN ", "N  B ", " NB N", "_ EN "],
        [" NX  ", "ENB B", " BENS", "NE  N", "NHN  ", " KNEN", " BGNB", "_ E N"]
    ],
    21: [
        ["ENXE ", "NBE B", "NNN  ", "HKNMN", "   SN", " B  N", " EENN", "_ NBB"],
        ["X?E?S", "NNE?B", "NE?N?", "?BBN?", "N??NN", "?ENBN", " HB?E", "_ KNN"],
        [" XKHB", "MN NN", "BN BN", "N NN ", "E ENN", " SEEB", " N  N", "_ B E"],
        ["N XEN", "NNNEN", "N NEE", "NNKBE", " B  B", "SNHB ", " NM  ", "_  B "],
        ["B X N", " N EH", " NNBE", "N B  ", " KMBS", "ENNN ", " ENEN", "_ NBN"]
    ],
    22: [
        ["NX  N", "NB EE", "BGNHN", " B N ", " N N ", "BNEBE", " NN E", "_ NSM"],
        ["BEXNG", "BMNEN", "NB SH", "NN EB", "N  NN", "E E  ", " NNB ", "_  N "],
        [" E NX", " N NG", "NBNEE", " BN B", "N MNE", " NNBN", "  H B", "_ ENS"],
        ["EXNNE", "NB BB", "S   N", "MBN N", " BN G", "HN NE", " E NN", "_ EN "],
        ["XHNEN", "NSNNB", " N  N", "NBNB ", "EG E ", "E MNN", "   BN", "_ EB "]
    ],
    23: [
        ["X   N", "NKNBB", "S FB ", "  N H", "ENEEB", " NE N", " BNNN", "_ NNE"],
        ["NSBNX", "NEBBN", "  NN ", "NEEFK", "NNE N", "  EH ", "  N B", "_ N B"],
        ["   NX", " EK  ", "EENEF", "NBBBN", "NBNS ", "N   E", " NBNN", "_ HNN"],
        ["NXE  ", "F NB ", "NNK B", "E SEB", "  NNE", "NH  E", " NNNN", "_ BBN"],
        ["  X N", "NNNHN", "B EBB", "EE ES", "  BEN", "NNFBN", "  N K", "_ N N"]
    ],
    24: [
        ["NEHXE", "NNN N", " SBFG", " NBNN", "NE NN", " BE  ", "  NB ", "_  EB"],
        [" EN X", "HN  E", "NE NN", "B NF ", "GNNEB", "N NEB", " BB N", "_ SN "],
        ["B NNX", "NNN B", " ENE ", "NNGNE", " B  E", "NSNNB", " EH  ", "_ BF "],
        ["H XNN", "EN NB", " F NN", "NNB E", " E EN", "EBBNN", " SG  ", "_  BN"],
        ["NBXEN", "  BN ", "NFNNG", "B  HE", "EN N ", "NNBSB", " EN E", "_ N  "]
    ],
    25: [
        ["XNBS ", "NFBEN", "   BN", "E N B", " BN M", " ENNN", " ENE ", "_ ENH"],
        [" BBXM", "B  HN", "NNNNE", "NE NB", " BFE ", "ES NN", " E NN", "_  E "],
        ["NXBB ", "N NNE", "NSN E", "B BH ", " BNME", "EEE N", "   FN", "_ N N"],
        ["MXB  ", " ENE ", "  ENB", "NB  B", "NENEN", " EFNN", " HSNB", "_ N N"],
        ["XENN ", "N  NE", "EBNBN", "EEB  ", "B M S", "NNFNN", " N HE", "_ B  "]
    ],
    26: [
        ["X HNB", " NN E", "ENN N", "EE N ", "B BE ", "EBN  ", " NN  ", "_  NS"],
        ["NEXNN", "NNNS ", "N BN ", "BEE  ", " N BB", "N  H ", "  EEE", "_  N "],
        ["ESXBN", "EEN B", "BE NN", "BNE N", "N  H ", "ENN  ", " N N ", "_    "],
        ["NNNXN", "EN EB", "B    ", "NH  N", " ES N", "N NB ", " E EE", "_ N B"],
        [" SEBX", " NNN ", "NH BN", "NNEN ", "E  E ", "  N B", " EEB ", "_ NN "]
    ],
    27: [
        ["EXB E", " SNN ", "EBNB ", "NE NB", " E N ", "NNEFN", "     ", "_  NN"],
        ["N EXE", "NNNBN", " B   ", "E ENN", " EN S", "N F B", " BN N", "_   E"],
        ["X NB ", "S EE ", "FNNBN", " N BN", "EB EN", "NN EE", "     ", "_ N N"],
        ["ENX  ", "EFNEN", " BN S", " NBNE", "N EB ", "  E N", " BNNN", "_    "],
        ["NXE E", "NNB  ", "B N N", "NBF N", "NE N ", "EE  N", " SB  ", "_ NE "]
    ],
    28: [
        ["ENXNE", "NNEBM", "  NSN", "NN  B", "EBB  ", "NN   ", "   EN", "_   E"],
        ["  SEX", "NENBN", " NB  ", "N E N", " ENE ", "NN  N", " BNBE", "_  M "],
        ["S XEE", "   N ", "NNBNN", "NNM B", " NN E", "BE EN", "  B  ", "_ E N"],
        ["E NNX", "N EEN", "N BE ", "  E  ", " NBNM", "ENN B", " NB N", "_   S"],
        ["N XNN", "B  EB", "B E  ", "ENEN ", "N M B", "SNN N", " N  N", "_  EE"]
    ],
    29: [
        [" N  X", "  N  ", "BNN N", "EE N ", "E NE ", "NSNEE", " NNBG", "_ B B"],
        ["E ENX", "GE  E", "N  NN", "B BNE", "NNNN ", "  BBE", " NN  ", "_  S "],
        ["EX  N", "NN  B", " EBS ", " NN N", "  NN ", " NBEN", "  EEB", "_ GEN"],
        ["X EEG", "BNN  ", "N NE ", "BNE B", "NBE S", " N NN", " NN  ", "_  E "],
        ["XB   ", " NES ", "NN  N", "NN BN", "NENN ", "EEEGB", "   EB", "_   N"]
    ],
    30: [
        ["EBEXN", " NBE ", "     ", "  NEE", "NNNK ", " S NN", " BNNN", "_  EB"],
        ["NNXB ", "NEEN ", " N   ", " EENN", " N BB", "NES  ", " E BN", "_  NK"],
        ["E BXB", "S N E", "ENNNN", "BE  N", " E N ", "B  NN", "  ENK", "_  N "],
        ["NEX  ", "ENN  ", "NB EN", "NBN E", "NN   ", "   KS", " BBEN", "_ N E"],
        ["BEBX ", "NKN  ", "E   N", "NSNN ", "BN  N", "N  EN", "  E N", "_ EEB"]
    ],
    31: [
        ["NNXN ", "  NBE", "  NSN", " NB N", " NNFB", "N HEE", " N BE", "_ E E"],
        ["NNXFN", "BE NN", "   NB", "E    ", "EENNB", "N B N", " NHEE", "_ NS "],
        [" N XN", "N BNN", "SEEBF", "NNBNN", "NE   ", " H N ", " EEEB", "_   N"],
        ["B HEX", "NE EB", "BSNNE", " FN  ", "NN BN", "NE EN", " N  N", "_   N"],
        [" EXNB", " EEB ", "NNNNH", "NB N ", "N NFN", "  E  ", " NES ", "_ ENB"]
    ],
    32: [
        ["BEXNB", "N N N", "S  HN", "N   E", "  ENN", "M EEN", " B NE", "_ NBN"],
        ["XE N ", " HSE ", "NN MN", " ENNN", "NN NE", "BB BE", "  E B", "_  NN"],
        ["XNEN ", "BB EE", "   E ", "NNBSN", " BM N", "N N N", " NNHE", "_ EN "],
        ["NNMXB", " NN  ", "NE N ", "BN  B", "ENE  ", "HEN N", " B EN", "_ NSE"],
        ["S ENX", " NN N", " NNNE", "EEBE ", "N BN ", "N MEB", "  H  ", "_ BNN"]
    ],
    33: [
        ["GEXB ", "  NEN", "BNSE ", "N NEE", " NNB ", "N HBN", "  N E", "_ NE "],
        ["E  BX", "S B B", " NNB ", "EEN N", "GNNH ", "ENEE ", " NN N", "_ EN "],
        ["EX  E", "HEEBN", "E  NN", "G B N", "B NN ", "  SNE", " ENN ", "_ NNB"],
        ["HEBX ", "NBN E", "BNNSN", "N  B ", "N  EN", "EGNE ", " E NE", "_  N "],
        ["NXEHE", " BENB", "  EGN", "EE NN", " N  S", " NNNE", "  NBN", "_  B "]
    ],
    34: [
        ["EXNEN", "BHEE ", "NE N ", "N   E", "  BNN", "NB NN", " BN S", "_ EK "],
        ["B EXB", "BNEN ", "KNSNN", "EEENE", " E   ", "NNN B", "   NN", "_   H"],
        [" SEX ", " N EN", "N KNB", "NN NB", "E BNE", "EN B ", " EH  ", "_ NEN"],
        ["   BX", " ENNN", "N K N", "BNEE ", "EBNNN", "ES BH", "  N N", "_  EE"],
        ["NXSE ", "NEN  ", "ENEBE", "H  NE", " B NE", "B K B", " N NN", "_  NN"]
    ],
    35: [
        ["NEEXN", " NNNN", "BNN  ", "NMBS ", "  E E", "  N E", "  NEB", "_ EBF"],
        ["NXEEE", "B NN ", "N N S", "EB F ", "NENE ", "NN  N", " ENBB", "_   M"],
        ["ENX N", "NBBNE", "F ENB", "NEB N", "SE  N", " EN M", " NE  ", "_ N  "],
        ["??E?S", "BEBFN", "N    ", "NNNNN", "NE EN", " N ME", " E  B", "_ ENB"],
        ["   EX", " ENNF", "E N B", " N EN", "E NBN", "NNNEE", " S BB", "_ MN "]
    ],
    36: [
        [" ESEX", " NEE ", "N NNN", "NNE E", " BFB ", "E NNB", "  GB ", "_  NN"],
        [" XNB ", " EGN ", "ENFEN", "NN NN", "B N E", "SE EE", " NB  ", "_  BN"],
        ["BXNBE", "NNB N", " NNEN", "  NE ", "  NEE", "  SGF", " NENE", "_  B "],
        ["B XN ", " B NE", " NE N", " EE S", " BBNF", " NENN", " NNNE", "_ GE "],
        ["G XNE", " EB  ", "N ENS", "ENB F", "  NNE", "EE  N", " NNNN", "_  BB"]
    ],
    37: [
        ["XNBNB", "N NE ", "E N  ", " SE B", "NEKBE", "FN   ", " NNN ", "_ ENE"],
        ["FEBXE", "   N ", " N NN", " ENEB", "NB N ", "K NBE", " EE N", "_ NNS"],
        [" XNNN", "N EE ", "EF  N", " NKN ", "B  ES", "BEB N", " EBEN", "_ NN "],
        ["BXNSE", " BK N", "E NBE", " F NN", "N EE ", "  NBE", " NNEN", "_ N  "],
        [" NEFX", "B  BN", "KBN B", " NNS ", "EENEN", "ENN  ", " N NE", "_ E  "]
    ],
    38: [
        ["XEN E", "NB BN", "E NNS", "EGNNE", " NE N", " BN  ", "  B  ", "_ EMN"],
        ["NBXNE", "N?N??", "?BN??", "EE?EN", "S?NMB", "E?NB?", " ??EN", "_ NNE"],
        ["XNEE ", "N NEN", " N M ", "NE GB", "N  EE", " N BE", " NB B", "_ SNN"],
        ["XB  M", "NENE ", "NNN E", "NNE B", "BN S ", "GNEEN", "    E", "_  NB"],
        ["EBXNB", "  EEN", "N NNG", " E M ", "BNNE ", "ENN N", "  E B", "_ NS "]
    ],
    39: [
        ["XEEEE", "  NNM", "NBK E", "N BNE", "SNNB ", "N    ", " NN  ", "_ ENB"],
        ["B N X", " EMN ", " EBEN", " K   ", "EN NN", "SN EN", " NBEB", "_ NNE"],
        ["XNBB?", "NN SN", "NNN N", " ?  ?", "B  B ", "N  ??", " ??N?", "_ NKM"],
        ["EBNXN", "EBENE", "N  SE", "M  N ", "B N E", "N N N", "  NKN", "_  BE"],
        [" EXE ", "NNNBE", "  BKN", " ENNB", "EMEN ", "EN  N", " B NN", "_   S"]
    ],
    40: [
        ["  EEX", "ENE E", "N N S", "B BNN", "N    ", " BKEN", " BGNN", "_ NNE"],
        [" NEXE", " ENB ", "N  B ", "BN N ", "NN  G", "EE NN", " SENK", "_ EBN"],
        ["XEBGB", "NKNBN", "N  N ", " NNEN", "N    ", "NNB E", " EEE ", "_  ES"],
        ["NEBXN", "N NE ", "NNENS", "    E", "E GNE", "NBE B", "    N", "_ NKB"],
        ["BXNN ", "EEE  ", "NG NN", "E   K", "B NNN", "BN EE", " NSNE", "_ B  "]
    ],
    41: [
        [" XBNS", " NE E", "NB EB", "NNENE", "E N N", "BGN  ", " NEKE", "_ NM "],
        [" EEXG", " NBBE", "K N N", "NNE N", "NEESM", "NBEN ", "  BNE", "_  N "],
        ["NEEXE", "  B E", " B  G", "ENNNM", "E  NN", "EKBNN", "  NNE", "_ SBN"],
        ["E NNX", "BGENS", "BEEEM", "KN BE", "  EEB", " NN  ", " NNNN", "_   N"],
        ["X E E", "NNN K", " B NN", "NNNE ", " NBG ", "BEEEM", " NS B", "_ EEN"]
    ],
    42: [
        [" F XE", "NNGS?", " NK N", "NBB N", " ENEN", " ?NB ", " BEEE", "_ ENE"],
        ["NXBNN", "NEE E", "KN  S", "ENBEB", "N F  ", " BE G", " ENNN", "_  EN"],
        ["NEXNE", "EES  ", "B N E", "B NNF", "NNN K", "   EB", " NNNG", "_ BEE"],
        ["N BBX", "E  NN", "EE FE", "BE N ", "NNEKN", " NNSG", " EBN ", "_ N E"],
        ["NN NX", " E E ", "NNNN ", "GN N ", "FBENE", "BKNEE", "   ES", "_ BEB"]
    ],
    43: [
        ["XBNB ", "ENMKN", "S NNB", "  NEN", " NBEN", " E E ", " EE E", "_ FNN"],
        [" XFNN", "N NEE", "BNS  ", "E NBB", "MNNBE", "E  EN", " K N ", "_ ENE"],
        ["XEBE ", "EKENN", "BMSB ", "ENB N", "FNN N", "ENEE ", " NN N", "_    "],
        ["FXENN", "E E E", "BBM  ", "  EK ", "N NNB", " ESNN", " BEEN", "_ NNN"],
        ["?X ?N", "BBNN ", "NF  B", "N?MN ", "??BN?", "K?N S", " NN? ", "_ N ?"]
    ],
    44: [
        ["ENBXN", "N??EF", "N?N?E", "?NN?B", "BEN?G", "??EE?", " SMEN", "_ ENN"],
        ["NXNFB", "E  B ", "E S N", "NEB N", "N EEE", "ENBNM", "  GNN", "_ N E"],
        ["NXNEB", "NB  S", "NNEB ", "NFEEE", "ENNE ", "EG NN", " B   ", "_ MN "],
        ["EXENB", " NENN", " BNS ", "BEFEE", "M  BE", "E NG ", " N NN", "_ N N"],
        ["EXNBN", "NE E ", " EB B", "ENNNN", "EN  B", " NM E", " ES F", "_ NNG"]
    ],
    45: [
        ["XNEEN", "H  E ", "BBEE ", " NB N", "NNNK ", "NENNN", " EG  ", "_ BES"],
        ["BX  H", "NN NB", "NS NE", "B E N", "NNN G", "BEEKE", " ENE ", "_ E N"],
        ["N EXN", "E ?KN", "E?S  ", "NE??B", " E?EB", "NB  N", " HENN", "_ BNE"],
        [" XEN ", " NEN ", "BEENN", "NENBE", "EEN  ", "G NBK", " NBSN", "_   H"],
        ["NSX  ", " NBK ", "NENHB", "NNG N", " E BN", "NNEE ", " EBEE", "_ NE "]
    ],
    46: [
        ["NNEXN", "NEMEN", "EBE N", "ENB N", "BHNE ", "EN K ", "  S  ", "_  NB"],
        ["KNEBX", "SEE N", "  EBE", "HNEN ", " BNEN", " NMB ", "  ENN", "_ N N"],
        [" HBNX", "E EKE", "BSNEN", "B NNE", "N   E", "ENENN", " MNB ", "_   N"],
        ["MXNNN", "HNN  ", "  NEN", "EN B ", "NESBK", "NE BN", "  B E", "_ EEE"],
        ["E X N", "N  EB", "NNBES", "NHKE ", "EENEN", "NNM N", " B   ", "_ ENB"]
    ],
    47: [
        [" HXEN", "BGESN", " BNNN", "NE E ", "N  BN", "NEMEN", " EE B", "_ N  "],
        ["B XBH", "S NE ", " EMG ", " NNBE", "EN  N", "NE BE", " NNNN", "_ ENE"],
        ["NSXN ", "NN NN", "NBNE ", "EE B ", "  BN ", "EEEGN", " MNB ", "_ EHE"],
        ["N EXE", " MBNE", "  NB ", "HNB N", "ENNEG", "SB EE", " NNN ", "_ E N"],
        ["X BEE", "EN   ", "NEE  ", "NEHBM", "NNNNB", " EGNN", "  SN ", "_ ENB"]
    ],
    48: [
        ["NNX E", "HN FE", "EN EE", "NBN  ", " BNBN", "  SN ", " ENBE", "_ ENK"],
        [" XBNN", " EEEN", " NNNE", "NSBF ", "ENN B", "HNKEE", "  E  ", "_  BN"],
        ["NK X ", "S EEN", "NENN ", "E HNB", "NN  N", "EE FN", " NEB ", "_ BEB"],
        ["F   X", "EENNN", "N  NE", "NNBNK", "EEE E", "N NBE", "  BNH", "_ SB "],
        ["SNX N", "FEEE ", "BNB E", "N BEH", "EE EN", "BNNK ", "   NN", "_  NN"]
    ],
    49: [
        ["NXE N", " BENE", "NHNEN", " BBE ", "GE SN", "NB N ", " N  F", "_ EEE"],
        [" N EX", "EEE E", "EBNFB", " NNN ", "N BSE", "NGENH", "  N N", "_ B E"],
        ["  HXF", "EENEN", "EBENB", "B NNN", "  N  ", " NEES", "  NEB", "_ NEG"],
        ["X  NN", "ESEEN", " GN B", " NE N", "BFHNE", "E EN ", " EN B", "_ ENB"],
        ["EFX  ", "NNB N", " B NN", "ENBEN", "BEG E", "NEE  ", " NEN ", "_ EHS"]
    ],
    50: [
        ["XNNEN", " HEE ", " NNEN", "EBBE ", " ENFN", "NBS E", "  MBN", "_  E "],
        ["NENNX", "BE EN", "N  BH", " EM  ", "  ENB", "EN FE", " ENNE", "_ BNS"],
        [" NXN ", "EEBEN", " S  F", "HNBB ", "N NMN", "EENB ", "  NNE", "_ EEE"], // someone is off-by one here
        [" BEXM", "N NBE", "ENES ", "E BE ", " NENN", " NNNE", " N  E", "_ BHF"],
        ["NEMXN", "HBFBE", " NNE ", "SBE E", " NN  ", "E EEE", " N NN", "_ B N"]
    ]
};

const abbrev: Record<string, Entity> = {
    ' ': 'empty',
    'N': 'monster',
    'E': 'elite',
    'S': 'boss',
    'B': 'boulder',
    'G': 'globe',
    'F': 'feather',
    'M': 'map',
    'H': 'hammer',
    'K': 'book',
    'X': 'exit',
    '_': 'entrance'
};

export function getMaps(stage: number): (Entity|undefined)[][][] {
    return maps[stage]!.map(instance => instance.map(row => Array.prototype.map.call(row, cell => abbrev[cell]) as (Entity|undefined)[]));
}

